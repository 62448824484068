body {
  overflow-x: hidden;
}


.main_nav_body {
  background-color: rgb(225, 219, 239);

}

.div_second_body {
  background-color: #8a8989;
  color: #000;

}

.nav-link {
  color: black !important;
  cursor: pointer;
}

.feature_head {
  font-family: Impact, Haettenschweiler, sans-serif, "red rose";
  color: rgb(3, 12, 103);
}

.about_us_cont {
  font-family: sans-serif, "red rose";
  color: rgb(2, 6, 58);
}

.mission_box {

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Align items horizontally center */
  justify-content: center;
  /* Align items vertically center */
  text-align: center;
  /* Align text center */
}

.mission_box ul {
  list-style: disc;
  /* Ensure bullet points are displayed */
  padding-left: 0;
}

.mission_box li {
  text-align: left;
  /* Align list items to left */
}

.vision_box {
  border: 1px solid black;
  border-radius: 10px;
}

.mission_font {
  color: rgb(3, 12, 103);
  font-family: Impact, Haettenschweiler, sans-serif, "red rose";
}

.mission_sub {
  color: rgb(3, 12, 103);
  ;
}

.rocket_class {
  border-radius: 50%;
  color: black;
}

.our-class-card {
  border: none;
  border-radius: 14px;
  background-color: #FFF;
  box-shadow: 0px 0px 40.026px 0px rgba(0, 0, 0, 0.10);
}

.our-class-title {
  color: #000;
  font-family: Nunito Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.our-class-text {
  color: #263238;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.285px;
}

.view-btn {
  color: #FFF !important;
  background-color: rgb(46, 3, 156) !important;
  font-family: Nunito Sans;
  font-size: 14.231px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.main_contact_cont {
  background-color: rgb(46, 3, 156) !important;
  color: whitesmoke;
  padding: 0;
  border-radius: 10px;
}



.appDownload {
  border-radius: 6px;
  background-color: rgb(46, 3, 156) !important;

}

.btn_subs {
  border: 1px solid whitesmoke !important;
  color: whitesmoke !important;
}

.main_logo {
  width: 3rem;
  height: 4rem;
}

.banner_class {
  width: 100%;
}

.first_img {
  border-radius: 10px;
}

.cont_abt_comp {
  color: rgb(18, 2, 60) !important;
}

.abt_comp {
  border-radius: 10px;
  border: 1px solid yellow;
}

.main_div_rev {
  background-color: black;

}

.review_head {
  font-family: Impact, Haettenschweiler, sans-serif, "red rose";
  color: whitesmoke;
}

.sub_rev_cont {
  color: whitesmoke !important;
}

.revie_para {
  color: rgb(212, 209, 209);
}

.contact_logo {
  width: 8rem;
  height: 8rem;
}

.adre_para {
  font-size: 20px;
  font-family: Impact, Haettenschweiler, sans-serif, "red rose";
  color: rgb(26, 26, 26);
}

.main_contact_div {
  background-color: rgb(185, 193, 208);
}

.contact_para {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgb(23, 23, 23);
}

.class_head {
  font-family: Impact, Haettenschweiler, sans-serif, "red rose";
  color: rgb(0, 0, 0);
}

.class_para {
  font: inherit;
  vertical-align: baseline;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
}

.post_img {
  height: 100%;
}

.class_ielt {
  font-family: Impact, sans-serif, "red rose";
  color: rgb(0, 0, 0);
}


.team-img {
  height: 370px;
  /* Adjust the height as needed */
  width: auto;
  /* Let the width adjust proportionally */
}

.team-img2 {
  height: 300px;
  /* Adjust the height as needed */
  width: auto;
  /* Let the width adjust proportionally */
}

.team-img3 {
  height: 370px;
  /* Adjust the height as needed */
  width: auto;
  /* Let the width adjust proportionally */
}

.what_up{
  width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000; /* Ensure it's above other content */
}

.class_point {
  cursor: pointer;
}

.what_up {
  margin-right: 2rem; /* Default margin */
}

@media only screen and (min-width: 576px) {
  .what_up {
    margin-right: 3rem; /* Margin for small devices and above */
  }
}

@media only screen and (min-width: 768px) {
  .what_up {
    margin-right: 4rem; /* Margin for medium devices and above */
  }
}

/* Add more media queries for other screen sizes as needed */


@media only screen and (min-width: 1198px) and (max-width: 1399px) {
  .team-img0 {
    height: 450px;
    /* Adjust the height as needed */
    width: auto;
    /* Let the width adjust proportionally */
  }
  .team-img1 {
    height: 350px;
    /* Adjust the height as needed */
    width: auto;
    /* Let the width adjust proportionally */
  }
  .team-img2 {
    height: 350px;
    /* Adjust the height as needed */
    width: auto;
    /* Let the width adjust proportionally */
  }
}


@media only screen and (min-width: 1400px) and (max-width: 2826px) {
  .team-img0 {
    height: 530px;
    /* Adjust the height as needed */
    width: auto;
    /* Let the width adjust proportionally */
  }
  .team-img1 {
    height: 430px;
    /* Adjust the height as needed */
    width: auto;
    /* Let the width adjust proportionally */
  }
  .team-img2 {
    height: 430px;
    /* Adjust the height as needed */
    width: auto;
    /* Let the width adjust proportionally */
  }
}

@media only screen and (min-width: 200px) and (max-width: 1199px) {
  .team-img0 {
    height: 370px;
    /* Adjust the height as needed */
    width: auto;
    /* Let the width adjust proportionally */
  }
  .team-img1 {
    height: 300px;
    /* Adjust the height as needed */
    width: auto;
    /* Let the width adjust proportionally */
  }
  .team-img2 {
    height: 300px;
    /* Adjust the height as needed */
    width: auto;
    /* Let the width adjust proportionally */
  }
}


@media only screen and (min-width< 425px) {
  .feature_head {
    font-size: 20px;
  }

  .review_head {
    font-size: 30px;
  }

  .class_head {
    font-size: 30px;
  }

  .class_ielt {
    font-size: 20px;
  }

  .revie_para {
    font-size: 15px;
  }

  .about_us_cont {
    font-size: 20px;
  }

  .mission_font {
    font-size: 16px;
  }

  .mission_sub {
    font-size: 12px;
  }

  .cont_abt_comp {
    font-size: 15px;
  }
}



@media only screen and (min-width: 425px) and (max-width: 768px) {
  .feature_head {
    font-size: 20px;
  }

  .review_head {
    font-size: 30px;
  }

  .class_head {
    font-size: 30px;
  }

  .class_ielt {
    font-size: 20px;
  }

  .revie_para {
    font-size: 15px;
  }

  .about_us_cont {
    font-size: 20px;
  }

  .mission_font {
    font-size: 16px;
  }

  .vission_font {
    font-size: 16px;
  }

  .mission_sub {
    font-size: 12px;
  }

  .cont_abt_comp {
    font-size: 15px;
  }
}


@media only screen and (max-width: 767px) {
  .feature_head {
    font-size: 20px;
  }

  .review_head {
    font-size: 30px;
  }

  .class_head {
    font-size: 30px;
  }

  .class_ielt {
    font-size: 24px;
  }

  .revie_para {
    font-size: 15px;
  }

  .about_us_cont {
    font-size: 20px;
  }

  .mission_font {
    font-size: 16px;
  }

  .vission_font {
    font-size: 16px;
  }

  .mission_sub {
    font-size: 12px;
  }

  .cont_abt_comp {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature_head {
    font-size: 25px;
  }

  .review_head {
    font-size: 35px;
  }

  .class_head {
    font-size: 35px;
  }

  .class_ielt {
    font-size: 24px;
  }


  .revie_para {
    font-size: 15px;
  }

  .about_us_cont {
    font-size: 20px;
  }

  .mission_font {
    font-size: 18px;
  }

  .vission_font {
    font-size: 18px;
  }

  .mission_sub {
    font-size: 14px;
  }

  .cont_abt_comp {
    font-size: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature_head {
    font-size: 30px;
  }

  .review_head {
    font-size: 45px;
  }

  .class_head {
    font-size: 45px;
  }

  .class_ielt {
    font-size: 30px;
  }


  .revie_para {
    font-size: 20px;
  }

  .sub_rev_cont {
    font-size: 20px;
  }

  .about_us_cont {
    font-size: 20px;
  }

  .mission_font {
    font-size: 21px;
  }

  .vission_font {
    font-size: 21px;
  }

  .mission_sub {
    font-size: 16px;
  }

  .cont_abt_comp {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .feature_head {
    font-size: 30px;
  }

  .review_head {
    font-size: 45px;
  }

  .class_head {
    font-size: 40px;
  }

  .class_ielt {
    font-size: 24px;
  }

  .revie_para {
    font-size: 25px;
  }

  .about_us_cont {
    font-size: 20px;
  }

  .mission_font {
    font-size: 18px;
  }

  .vission_font {
    font-size: 18px;
  }

  .mission_sub {
    font-size: 14px;
  }

  .cont_abt_comp {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .feature_head {
    font-size: 40px;
  }

  .review_head {
    font-size: 60px;
  }

  .class_head {
    font-size: 50px;
  }

  .class_ielt {
    font-size: 50px;
  }

  .revie_para {
    font-size: 25px;
  }

  .about_us_cont {
    font-size: 30px;
  }

  .mission_font {
    font-size: 22px;
  }

  .mission_sub {
    font-size: 17px;
  }

  .cont_abt_comp {
    font-size: 15px;
  }
}

@media screen and (min-width: 2560px) {
  .feature_head {
    font-size: 60px;
  }

  .review_head {
    font-size: 70px;
  }

  .class_head {
    font-size: 60px;
  }

  .class_ielt {
    font-size: 60px;
  }


  .revie_para {
    font-size: 28px;
  }

  .about_us_cont {
    font-size: 50px;
  }

  .mission_font {
    font-size: 25px;
  }

  .vission_font {
    font-size: 25px;
  }

  .mission_sub {
    font-size: 18px;
  }

  .cont_abt_comp {
    font-size: 35px;
  }
}